import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import different CSS files based on device type
import mobileStyles from '../styles/index/mobile.css';
import desktopStyles from '../styles/index/desktop.css';

const IndexPage = () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const navigate = useNavigate();

    // Convert webSocketIP into a state variable
    const [webSocketIP, setWebSocketIP,] = useState('');
    const [cameraIP, setCameraIP] = useState('');

    const handleWebsocketIpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebSocketIP(event.target.value + ":81");
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCameraIP("http://"+ event.target.value +":4747/video");
    }

    const handleConnectClick = async () => {
        try {
            const websocket = new WebSocket(`ws://${webSocketIP}`);

            websocket.addEventListener('open', () => {
                console.log('WebSocket connected');
                if (isMobile) {
                    navigate('/mobile', { state: { webSocketIP ,cameraIP} });
                } else {
                    navigate('/desktop', { state: { webSocketIP ,cameraIP} });
                }
            });

            websocket.addEventListener('error', (error) => {
                console.error('WebSocket error:', error);
                alert('Failed to connect to WebSocket server. Please check the IP address.');
            });
        } catch (error) {
            console.error('WebSocket connection error:', error);
            alert('Failed to connect to WebSocket server. Please check the IP address.');
        }
    };

    return (
        <div className="index-page-container">
            <div className={isMobile ? mobileStyles.container : desktopStyles.container}>
                <h1>Welcome to carTrol v2</h1>
                <p>{isMobile ? 'You are on a mobile device' : 'You are on a desktop device'}</p>
                <div className={isMobile ? mobileStyles.inputContainer : desktopStyles.inputContainer}>
                    <input type="text" placeholder="Enter ESP32 IP" onChange={handleWebsocketIpChange} />
                </div>
                <div className={isMobile ? mobileStyles.inputContainer : desktopStyles.inputContainer}>
                    <input type="text" placeholder="Enter Camera IP" onChange={handleInputChange} />
                </div>
                <div className='button-container'>
                    <button onClick={handleConnectClick}>Connect</button>
                </div>
            </div>
        </div>
    );
};

export default IndexPage;
