import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DesktopControl: React.FC = () => {
    const location = useLocation();
    const [websocket, setWebsocket] = useState<WebSocket | null>(null);
    const [lastDirection, setLastDirection] = useState<string>('stop');

    const webSocketIP: string = location.state.webSocketIP;
    const cameraIP: string = location.state.cameraIP;

    useEffect(() => {
        const directionMap: { [key: string]: string } = {
            'w': 'FORWARD',
            'a': 'LEFT',
            's': 'BACKWARD',
            'd': 'RIGHT',
            ' ': 'STOP', // Spacebar
        };

        if (webSocketIP) {
            const ws = new WebSocket(`ws://${webSocketIP}`);
            ws.onopen = () => {
                setWebsocket(ws);
                console.log('WebSocket connected');
            };
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            const sendControlCommand = (direction: string) => {
                if (websocket) {
                    websocket.send(direction);
                    console.log("Sending command:", direction);
                }
            }

            const handleKeyDown = (event: KeyboardEvent) => {
                console.log("Key pressed:", event.key);
                const direction = directionMap[event.key];
                console.log("Direction:", direction);
                if (direction) {
                    if (lastDirection !== direction) {
                        setLastDirection(direction);
                        sendControlCommand(direction);
                    }
                }
            };

            const handleKeyUp = (event: KeyboardEvent) => {
                const direction = directionMap[event.key];
                if (direction === lastDirection) {
                    setLastDirection(directionMap[' ']); // Stop when key is released
                    sendControlCommand(directionMap[' ']);
                }
            };

            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyup', handleKeyUp);
            };
        }
    }, [location.state, websocket, lastDirection, webSocketIP]);

    const handleToggleClick = () => {
        if (websocket) {
            websocket.send('toggle');
            console.log("Sending command: toggle");
        }
    };

    return (
        <div>
            <h1>carTrol v2</h1>
            <p>Connected to: {webSocketIP}</p>
            <div className="video-box">
                {/* Embed your video component here */}
                <iframe
                    title="Live Video Stream"
                    src={cameraIP} // Use the videoUrl variable here
                    frameBorder="0"
                    allowFullScreen
                    width="640"
                    height="480"
                ></iframe>
            </div>
            <button onClick={handleToggleClick}>Toggle Headlight</button>
        </div>
    );
};

export default DesktopControl;
