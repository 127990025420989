// NotFoundPage.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/notFound/NotFoundPage.css'; // Import CSS for styling

const NotFoundPage = () => {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1>404 - Page Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
                <Link to="/" className="not-found-link">Go to Home Page</Link>
            </div>
        </div>
    );
};

export default NotFoundPage;
