import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IndexPage from './views/indexPage';
import MobileControl from './views/mobileControl';
import DesktopControl from './views/desktopControl';
import NotFoundPage from './views/notFound'; // Corrected import name

function App() {
    const version: string = (process.env.REACT_APP_VERSION ?? '').substring(0, 7) || 'Release';
    const environment: "dev"|"prod" = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';

    return (
        <BrowserRouter>
            <div style={{ position: 'relative', minHeight: '100vh' }}>
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/mobile" element={<MobileControl />} />
                    <Route path="/desktop" element={<DesktopControl />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <div style={{ position: 'fixed', bottom: '10px', left: '10px', padding: '10px', borderRadius: '5px' ,color: '#606060'}}>
                    <span>Version: {version}-{environment}</span>
                    <br/>
                    <span>Made with love by HaeImAlan</span>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
