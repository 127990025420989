import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Joystick } from 'react-joystick-component';
import '../styles/mobileControl.css';

const MobileControl: React.FC = () => {
    const location = useLocation();
    const [websocket, setWebsocket] = useState<WebSocket | null>(null);
    const videoUrl: string = location.state.cameraIP;
    var lastDirection: string = 'stop';
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const directionMap: { [key: string]: string } = {
        up: 'forward',
        down: 'backward',
        left: 'left',
        right: 'right',
        stop: 'stop',
    };

    useEffect(() => {
        const webSocketIP: string | undefined = location.state?.webSocketIP;

        if (webSocketIP) {
            const ws = new WebSocket(`ws://${webSocketIP}`);
            ws.onopen = () => {
                setWebsocket(ws);
                console.log('WebSocket connected');
            };
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            // Clean up WebSocket connection when component unmounts
            return () => {
                //ws.close();
            };
        }
    }, [location.state, websocket]);

    const sendControlCommand = (direction: string, distance: number) => {
        if (lastDirection === direction) {
            console.log('Same direction');
        } else if (direction === 'STOP') {
            setTimeout(() => {
                console.log('Sending command:', direction);
                websocket?.send(direction);
            }, 150);
        } else {
            console.log('Direction:', direction);
            lastDirection = direction;
            websocket?.send(direction);
        }

        if (websocket) {
            const command = directionMap[direction];
            if (command) {
                //console.log('Sending command:', command)
                //websocket.send(command);
            }
        }
    };

    const resizeIframe = () => {
        const iframe = iframeRef.current;
        if (iframe) {
            const width = iframe.offsetWidth;
            const aspectRatio = 16 / 9; // Change this to match the aspect ratio of your video
            const height = width / aspectRatio;
            iframe.style.height = height + 'px';
        }
    };

    useEffect(() => {
        resizeIframe();
        window.addEventListener('resize', resizeIframe);
        return () => {
            window.removeEventListener('resize', resizeIframe);
        };
    }, []);

    return (
        <div className="mobile-control-page">
            <h1>carTrol v2</h1>
            <p>JoyStick mode (beta).</p>
            {/* Wrapper div for centering the joystick */}
            <div className="joystick-wrapper">
                <Joystick
                    size={100}
                    baseColor="rgba(49, 54, 63, 0.3)" // Dark background color with transparency
                    stickColor="rgba(238, 238, 238, 0.7)" // Light stick color with transparency
                    move={(e: any) => sendControlCommand(e.direction, e.distance)} // Send control commands based on joystick movement
                    stop={() => sendControlCommand('STOP', 0)} // Send stop command when joystick is released
                />
            </div>
            <div className="video-box">
                {/* Embed your video component here */}
                <iframe
                    title="Live Video Stream"
                    src={videoUrl} // Use the videoUrl variable here
                    frameBorder="0"
                    allowFullScreen
                    width="640"
                    height="480"
                ></iframe>
            </div>
        </div>
    );
};
export default MobileControl;
